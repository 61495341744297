import { defineMessages } from "react-intl";

export const globalMessages = defineMessages({
  copyToClipboard: {
    id: "global_copyToClipboard",
    defaultMessage: "Zkopírovat do schránky"
  },
  copyToClipboardFinished: {
    id: "global_copyToClipboard_finished",
    defaultMessage: "Zkopírováno do schránky"
  },
  copyToClipboardUnavailable: {
    id: "global_copyToClipboard_unavailable",
    defaultMessage: "Zkopírování do schránky není dostupné. Aplikace pravděpodobně neběží v zabezpečeném režimu (https)."
  },
  ok: {
    id: "global_ok",
    defaultMessage: "Ok",
  },
  cancel: {
    id: "global_cancel",
    defaultMessage: "Storno"
  }
})
